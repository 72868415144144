import React from "react";
import Navbar from "./HeroSection/Navbar";
import Footer from "./Footer/Footer";

const TermsOfServices = () => {
  return (
    <div className={`bg-[#000000]`}>
      <Navbar />
      <div className="bg-[#1b1b1b] py-24 md:py-36 px-8 md:px-20">
        <div className="flex flex-row justify-center text-white text-3xl md:text-5xl font-medium font-poppins">
          Terms of Service
        </div>

        <div className="mt-12 text-white text-xl md:text-2xl font-medium font-poppins">
          Service Description:
        </div>
        <div className="mt-4 text-white text-base md:text-lg font-medium font-poppins">
          Alphaquark.in operates as a platform where advisors send investment
          advice to clients and facilitate order execution.
        </div>

        <div className="mt-12 text-white text-xl md:text-2xl font-medium font-poppins">
          Payment Terms:
        </div>

        <div className="mt-4 text-white text-base md:text-lg font-medium font-poppins">
          Users are responsible for all fees associated with their selected
          service plan. Fees are billed monthly or annually based on the chosen
          plan and are non-refundable. Alphaquark.in reserves the right to
          modify fee structures with 30 days' notice. Failure to make payments
          may lead to suspension or termination of services.
        </div>

        <div className="mt-12 text-white text-xl md:text-2xl font-medium font-poppins">
          Termination:
        </div>

        <div className="mt-4 text-white text-base md:text-lg font-medium font-poppins">
          Users can cancel their subscription at any time. No refunds will be
          issued for partial billing periods.
        </div>

        <div className="mt-8 text-white text-base md:text-lg font-medium font-poppins">
          By using Alphaquark.in, you agree to abide by these terms of service.
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsOfServices;
