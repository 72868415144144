import React, { useState } from "react";

//React Icons
import { AlignJustify, X } from "lucide-react";

import Logo from "../assests/Logo.jpg";
import MobileNav from "./MobileNav";
import { Link, useLocation } from "react-router-dom";
const Navbar = () => {
  const location = useLocation();
  const [navOpen, setNavOpen] = useState(false);

  const closeMobileNav = () => {
    setNavOpen(false);
  };

  // console.log("loclatijn=====", location.pathname);
  return (
    <>
      <nav className="w-full py-3  md:py-3 flex flex-row items-center justify-between fixed hrefp-0 shadow-sm backdrop-blur-lg  z-50 font-poppins bg-[#000000]/30">
        {/*------------------Logo--------------------------*/}
        <a href={"/"} className="flex flex-row items-center ml-6 lg:ml-20">
          <img src={Logo} alt="Logo" className="w-10 h-10 rounded-md" />
          <div className="text-xl text-white font-bold   ml-3 cursor-pointer flex flex-col items-center select-none">
            Alphaquark
          </div>
        </a>

        {/*------------------NAV a--------------------------*/}

        <div className="flex flex-row items-center justify-between lg:justify-start  mr-6 lg:mr-20">
          <Link to="/advisors">
            <div className=" underline-animation ml-4 px-3 py-1 text-neutral-300 rounded-lg hover:text-neutral-50 hover:scale-110 active:scale-95 ease-linear duration-150 hidden lg:flex  cursor-pointer">
              Advisors
            </div>
          </Link>

          <Link to="/investors">
            <div className=" underline-animation ml-4 px-3 py-1 text-neutral-300 rounded-lg hover:text-neutral-50 hover:scale-110 active:scale-95 ease-linear duration-150 hidden lg:flex  cursor-pointer">
              Investors
            </div>
          </Link>

          <a
            href="https://test.alphaquark-case.com/"
            target="_blank"
            rel="noreferrer noopener"
            className="ml-4"
          >
            <button className="shine hidden md:flex text-white font-medium text-sm px-6 py-[8px] gradient-blue rounded-md hover:scale-105 active:scale-95">
              Login/Register
            </button>
          </a>

          {/*------------------------Live Portfolio------------------------ */}

          {/*-----------------Mobile Nav hrefggle--------------- */}
          <div
            onClick={() => setNavOpen(!navOpen)}
            className="text-neutral-50  ml-10 cursor-pointer
            flex lg:hidden"
          >
            {navOpen ? <X size={25} /> : <AlignJustify size={25} />}
          </div>
        </div>
      </nav>
      {navOpen && (
        <MobileNav navOpen={navOpen} closeMobileNav={closeMobileNav} />
      )}
    </>
  );
};
export default Navbar;
