import React from "react";
import ConnectNow from "../common/connectNow";
// import Navbar from "./Navbar";
import { TypeAnimation } from "react-type-animation";
import Mckiensy from "../assests/mckinsey&company.svg";
import Jpmorgan from "../assests/JPmorgan.svg";
import Flipkart from "../assests/flipkart.svg";
const HeroSection = (props) => {
  return (
    <div className="  flex flex-col justify-center space-y-8 items-center py-28 md:py-48">
      <div className="px-8 hidden lg:block md:px-1 font-sans text-center">
        <div className="font-extrabold  text-white text-[20px] md:text-xl md:text-[52px] md:leading-[76px]">
          Boost your
          <TypeAnimation
            preRenderFirstString={true}
            sequence={["Trading", 3000, "Investment", 3000]}
            className="text-[#01B0EA] mx-3"
            speed={150}
            // speed={{type: 'keyStrokeDelayInMs', value: 100}}
            // cursor={false}
            deletionSpeed={150}
            wrapper="span"
            repeat={Infinity}
          />
          Performance
        </div>
        <div className="font-extrabold text-white text-[20px] md:text-xl md:text-[52px] md:leading-[76px] mt-1 md:mt-0">
          {" "}
          with Technology & Automation
        </div>
      </div>

      <div className="px-8 md:px-16 lg:px-1 lg:hidden font-sans text-center">
        <div className="font-extrabold  text-white text-[20px] md:text-[44px] md:leading-[76px]">
          Boost your
          <TypeAnimation
            preRenderFirstString={true}
            sequence={["Trading", 3000, "Investment", 3000]}
            className="text-[#01B0EA] mx-3"
            speed={150}
            // speed={{type: 'keyStrokeDelayInMs', value: 100}}
            // cursor={false}
            deletionSpeed={150}
            wrapper="span"
            repeat={Infinity}
          />
        </div>
        <div className="font-extrabold text-white text-[20px] md:text-[44px] md:leading-[76px] mt-1 md:mt-0">
          {" "}
          Performance with Technology & Automation
        </div>
      </div>

      <div className=" text-center text-[13px] leading-[24px]  md:text-[18px] md:leading-[32px] w-[90%] md:w-[85%]  lg:w-[50%] text-[#ffffff]/50 font-poppins">
        Transform your investment strategy execution with Alphaquark - where
        expert human insights are amplified with effective and efficient
        automations.
      </div>

      <div className="mx-8 bg-[#ffffff]/5 font-sans text-sm px-2 md:px-8 md:text-xl flex flex-col md:flex-row justify-center items-center font-semibold rounded-lg">
        <div className="flex flex-row justify-center items-center text-[#ffffff]/70 px-1 md:px-6 cursor-pointer hover:scale-110 transition duration-150 ease-linear leading-6 md:border-r-2 md:border-[#ffffff]/10 md:border-b-0 border-b-2 border-[#ffffff]/10 h-12">
          Built by leaders from
        </div>{" "}
        <div className="flex flex-row items-center ">
          <div className="py-8 px-3 md:px-6 cursor-pointer hover:scale-110 transition duration-150 ease-linear leading-6">
            <img src={Mckiensy} alt="Mckiensy" />
          </div>{" "}
          <div className="px-3 md:px-6 cursor-pointer hover:scale-110 transition duration-150 ease-linear leading-6">
            <img src={Jpmorgan} alt="Jpmorgan" />
          </div>{" "}
          <div className="px-3 md:px-6 cursor-pointer hover:scale-110 transition duration-150 ease-linear leading-6">
            <img src={Flipkart} alt="Flipkart" />
          </div>{" "}
        </div>
      </div>

      <div className="md:hidden "></div>
      <div className="flex flex-row items-center justify-center md:pt-8">
        <ConnectNow />
      </div>
    </div>
  );
};
export default HeroSection;
