import React from "react";
import HeroSection from "./HeroSection/HeroSection";
import AllPart from "./whatSetsApart/AllPart";
import Navbar from "./HeroSection/Navbar";
import styles from "./HeroSection/heroSection.module.css";
import Footer from "./Footer/Footer";
const HomePage = () => {
  return (
    <div className={`bg-[#000000]`}>
      <Navbar />
      <div className={`${styles.backgroundHero}`}>
        <HeroSection />
      </div>

      <AllPart />
      <Footer />
    </div>
  );
};

export default HomePage;
