import React from "react";

import Zerodha from "../assests/broker/Zerodha.svg";
import AngelOne from "../assests/broker/AngelOne.svg";
import IIFL from "../assests/broker/IFFL.svg";
import Icici from "../assests/broker/ICICIDirect.svg";
import Kotak from "../assests/broker/Kotak.svg";
import Upstox from "../assests/broker/Upstox.svg";
import Fyers from "../assests/broker/Fyers.svg";

const indiaMarket = [
  {
    broker: "Zerodha",
    imageURL: Zerodha,
  },
  {
    broker: "Angel One",
    imageURL: AngelOne,
  },
  {
    broker: "IIFL Securities",
    imageURL: IIFL,
  },
  {
    broker: "Icici",
    imageURL: Icici,
  },
  {
    broker: "Kotak",
    imageURL: Kotak,
  },
  {
    broker: "Upstox",
    imageURL: Upstox,
  },
  {
    broker: "Fyers",
    imageURL: Fyers,
  },
];

const SolutionOne = () => {
  return (
    <div className="">
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-2  lg:gap-y-2 lg:gap-x-2 ">
        {indiaMarket.map((ele, i) => {
          return (
            <div
              className="bg-[#ffffff] flex flex-row items-center mt-2 mr-2 px-8 py-3 rounded-md"
              key={i}
            >
              <img src={ele.imageURL} alt={ele.imageURL} className="w-32" />
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default SolutionOne;
