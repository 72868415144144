import React from "react";

const MobileNav = ({ navOpen, closeMobileNav, ...props }) => {
  return (
    <div
      className={`w-full h-full bg-black text-neutral-50 mt-12 pt-8 fixed top-0 z-40 font-Poppins transform transition ease-in duration-500   ${
        navOpen ? "translate-y-0 animate-slideInNav" : "-translate-y-full "
      }`}
    >
      <a href="#advisors" onClick={closeMobileNav}>
        <div className="text-neutral-50 hover:text-neutral-50">
          <div className="w-[85vw] px-2 py-4 border-b border-white/10 cursor-pointer mx-auto">
            Advisors
          </div>
        </div>
      </a>
      <a href="#investors" onClick={closeMobileNav}>
        <div className="text-neutral-50 hover:text-neutral-50">
          <div className="w-[85vw] px-2 py-4 border-b border-white/10 cursor-pointer mx-auto">
            Investors
          </div>
        </div>
      </a>

      {/*-------------------------Contact on Email-------------------- */}
      <a
        href="https://test.alphaquark-case.com/"
        target="_blank"
        rel="noreferrer noopener"
      >
        <div className="text-neutral-50 hover:text-neutral-50">
          <button className=" mt-4 shine ml-8 text-white font-medium text-sm px-6 py-[8px] gradient-blue rounded-md hover:scale-105 active:scale-95">
            Login/Register
          </button>
        </div>
      </a>
    </div>
  );
};

export default MobileNav;
