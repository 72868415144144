import React from "react";

import styles from "../HeroSection/heroSection.module.css";
// import useIsVisible from "../common/useIsVisibleRef";

import HumanStrategy from "../assests/Human-Machine.png";
import Automation from "../assests/automation.png";
import ParterShip from "../assests/Partnership.png";
import ConnectNow from "../common/connectNow";

// automation
// import Effeciency from "../assests/Effeciency.svg";
// import Emotion from "../assests/Emotion.svg";
// import Accessibility from "../assests/Accesibility.svg";

// import TrustedUser from "../assests/TrustedUser.svg";
// import User from "../assests/User.svg";
// import Quote from "../assests/quote.svg";

// import Marquee from "../marquee/marquee";
import Solution from "./Solutions";
import SolutionOne from "./SolutionOne";
const ApartData = [
  {
    title: "Human - Machine Synergy",
    desc: "Harness the power of human intuition combined with advanced automation techniques to unlock new realms of wealth creation.",
    imgUrl: HumanStrategy,
  },
  {
    title: "Automation",
    desc: "From Equities to Derivatives, if it's electronically tradable, we automate it, providing you with unparalleled efficiency and agility.",
    imgUrl: Automation,
  },
  {
    title: "Trusted Partnerships",
    desc: "We collaborate with leading exchanges and brokers, ensuring the highest standards of security and reliability for your investments.",
    imgUrl: ParterShip,
  },
];

// const TestimonialData = [
//   {
//     content: `Great! Compared to everything else I’ve ever used, this is the best technology.  used, this is the best .
// `,
//     imageUrl: TrustedUser,
//     name: "Larsen Black",
//     occupation: "Banker",
//   },
//   {
//     content: `Great! Compared to everything else I’ve ever used, this is the best technology.  used, this is the best best technology.  used, this .`,
//     imageUrl: TrustedUser,
//     name: "Ravendra Prajapati",
//     occupation: "Full Stack Developer",
//   },
//   {
//     content: `Great! Compared to everything else I’ve ever used, this is the best technology.  used, this is the best .
// `,
//     imageUrl: TrustedUser,
//     name: "Akshata Kenjale",
//     occupation: "UI/UX Designer",
//   },
//   {
//     content: `Great! Compared to everything else I’ve ever used, this is the best technology.  used, this is the best best technology.  used, this .`,
//     imageUrl: TrustedUser,
//     name: "Ravi",
//     occupation: "Banker",
//   },
//   {
//     content: `Great! Compared to everything else I’ve ever used, this is the best technology.  used, this is the best .`,
//     imageUrl: TrustedUser,
//     name: "Monu Singh ",
//     occupation: "Banker",
//   },
//   {
//     content: `Great! Compared to everything else I’ve ever used, this is the best technology.  used, this is the best best technology.  used, this .`,
//     imageUrl: TrustedUser,
//     name: "Larsen Black",
//     occupation: "Banker",
//   },
// ];

const AllPart = () => {
  return (
    <div className="bg-[#1b1b1b]">
      {/* one Platform endless solutions*/}
      <div className="py-20 md:py-24" id="features">
        <div className="flex flex-col space-y-4 lg:space-y-2 justify-center items-center">
          {" "}
          <div
            className={`font-sans text-md md:text-xl font-semibold ${styles.text_gradient}`}
          >
            SOLUTIONS
          </div>
          <div
            className={`text-center font-extrabold text-white text-[18px] md:text-[38px] md:leading-[60px] font-sans`}
          >
            One Partner , Many Solutions
          </div>
        </div>
        <Solution />
        <div className="flex flex-row justify-center mt-20">
          <ConnectNow />
        </div>
      </div>
      <div className="bg-[#171717] py-20 md:py-28 px-4 md:px-[28px] lg:px-40">
        <div className={`grid grid-cols-1 md:grid-cols-2 items-center`}>
          <div className="flex flex-col px-2 md:px-16">
            <div
              className={`font-sans text-md md:text-lg font-semibold ${styles.text_gradient}`}
            >
              SUPPORTED BROKERS
            </div>
            <div className="font-extrabold text-white text-[24px]  md:text-[36px] md:leading-[60px] font-sans">
              Seamless Integration with Top Brokers for Effortless Trading
            </div>
            <div className="md:flex hidden md:mt-16">
              <ConnectNow />
            </div>
            <div className="text-center md:hidden my-12">
              <ConnectNow />
            </div>
          </div>
          <SolutionOne />
        </div>
      </div>
      {/* what sets apart*/}
      <div className="py-20 md:py-24" id="why-alphaquark">
        <div className="flex flex-col space-y-4 lg:space-y-2 justify-center items-center ">
          {" "}
          <div
            className={`font-sans text-md md:text-xl font-semibold ${styles.text_gradient}`}
          >
            WHY US
          </div>
          <div className="font-extrabold text-white text-[18px]  md:text-[38px] md:leading-[60px] font-sans">
            What Sets us Apart ?
          </div>
        </div>
        <div
          className={` grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-10 md:gap-y-10 lg:gap-y-0 md:gap-x-10 px-8 md:px-[70px] lg:px-32  mt-8`}
        >
          {ApartData.map((ele, i) => {
            return (
              <div key={i}>
                <div className={`w-full flex flex-col mt-3 md:mt-0  `}>
                  <div className="overflow-hidden">
                    <img
                      src={ele.imgUrl}
                      className="w-full rounded-xl h-[250px]  "
                      alt={ele.title}
                    />
                  </div>
                  <div className="w-full text-left  font-sans font-bold text-[18px] md:text-[22px] leading-[50px] text-white mt-4">
                    {ele.title}
                  </div>
                  <div className="w-full text-left  text-sm md:text-[16px] leading-[26px] text-[#ffffff]/50 font-poppins mt-2">
                    {ele.desc}
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="flex flex-row justify-center mt-20">
          <ConnectNow />
        </div>
      </div>

      {/* trusred by
      <div className={`py-20 md:py-24  `} id="testimonals">
        <div className="px-6 md:px-48 space-y-4 lg:space-y-2 flex flex-col justify-center items-center lg:justify-start lg:items-start ">
          <div
            className={`font-sans text-md md:text-xl font-semibold ${styles.text_gradient}`}
          >
            CLIENT TESTIMONIALS
          </div>
          <div className="font-extrabold text-white text-[18px]  md:text-[38px] md:leading-[60px] font-sans">
            Trusted by
          </div>
        </div>
        <Marquee testimonialData={TestimonialData} />
        <div className="flex flex-row justify-center mt-20 md:mt-28">
          <ConnectNow />
        </div>
      </div>
      */}
      {/* Automation
      <div className="py-20 md:py-24" id="automation">
        <div className="flex flex-col space-y-4 lg:space-y-2 justify-center items-center">
          {" "}
          <div
            className={`font-sans text-md md:text-xl font-semibold ${styles.text_gradient}`}
          >
            BENEFITS
          </div>
          <div className="font-extrabold text-white text-[18px]  md:text-[38px] md:leading-[60px] font-sans">
            Automate to Elevate !
          </div>
        </div>

        <div
          className={`mt-20 flex flex-col space-y-10 md:space-y-0 md:flex-row justify-between items-center px-4 md:px-10 lg:px-48`}
        >
          <div className="relative md:w-[30%] px-10 md:px-1 flex flex-col space-y-4  md:h-[350px] ">
            <div className="flex items-center justify-center lg:justify-start ">
              <img
                className=" w-[100px]  transition duration-150 ease-linear"
                src={Effeciency}
                alt="Effecifiency"
              />
              <div className="absolute w-[0%] md:w-[60%] h-[1px] bg-[#ffffff]/10 lg:-right-[20px]"></div>
            </div>
            <div className="flex flex-1 flex-col space-y-4  ">
              <div
                className={` w-full text-base  md:text-[22px] text-center md:leading-[30px] lg:text-left lg:leading-[50px] ${style.tradingHeading} `}
              >
                Efficiency & Speed
              </div>
              <div
                className={` w-full  text-xs md:text-[16px] leading-[26px] text-center lg:text-left  ${style.tradingDescription}`}
              >
                Automated trading executes orders at lightning speed, seizing
                opportunities in real-time with precision.
              </div>
            </div>
          </div>

          <div className="relative  md:w-[30%] px-10 md:px-1 flex flex-col space-y-4  md:h-[350px] ">
            <div className="flex  items-center justify-center lg:justify-start ">
              <img
                className=" w-[100px] transition duration-150 ease-linear"
                src={Emotion}
                alt="Speed"
              />
              <div className="absolute w-[0%] md:w-[60%] h-[1px] bg-[#ffffff]/10  lg:-right-[20px]"></div>
            </div>

            <div className="flex flex-1 flex-col space-y-4 ">
              <div
                className={`w-full text-base  md:text-[22px] text-center md:leading-[30px] lg:text-left lg:leading-[50px] ${style.tradingHeading} `}
              >
                Objective Trading
              </div>
              <div
                className={`text-xs md:text-[16px] leading-[26px]  md:w-[95%] text-center lg:text-left ${style.tradingDescription}`}
              >
                Remove human biases and emotions from the equation, ensuring
                consistent and disciplined trading decisions.
              </div>
            </div>
          </div>

          <div className="md:w-[30%] px-10 md:px-1 flex flex-col space-y-4   md:h-[350px] ">
            <div className="flex items-center justify-center lg:justify-start ">
              <img
                className=" w-[100px]  transition duration-150 ease-linear"
                src={Accessibility}
                alt="Accessibility}"
              />
            </div>
            <div className="flex flex-1  flex-col space-y-4 ">
              <div
                className={`w-full text-base  md:text-[22px] text-center md:leading-[30px] lg:text-left lg:leading-[50px] ${style.tradingHeading} `}
              >
                Always-On Trading
              </div>
              <div
                className={`text-xs md:text-[16px] leading-[26px]  md:w-[90%] text-center lg:text-left ${style.tradingDescription}`}
              >
                Trade non-stop, across time zones with our automated systems.
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-center mt-24">
          <ConnectNow />
        </div>
      </div>
      */}
      {/* build your future 
      <div className={` py-8 md:py-24 flex flex-row justify-center `}>
        <div
          className={`w-[90%] md:w-[80%] ${styles.linearGradient} rounded-[20px] shadow-lg border border-[#ffffff]/10`}
        >
          <div className="flex flex-col lg:flex-row ">
            <div className="w-full h-[450px] lg:h-auto lg:w-[30%]  overflow-hidden">
              <img
                src={User}
                alt="User"
                className="w-full rounded-[20px]  lg:rounded-none"
              />
            </div>
            <div className="flex flex-col  justify-between w-full lg:w-[70%] px-4 md:px-12 py-10">
              <div>
                <div className="w-full md:w-[95%] text-[14px] leading-[28px] md:text-[16px] md:leading-[30px] text-[#ffffff]/60 font-poppins">
                  Active managers who want to survive have to up their game.
                  That means better risk control, better stop-loss policy,
                  better bet sizing, better execution, better emotion control.
                  In a nutshell, that means better process. If investing is a
                  process, then automation is the logical conclusion.
                  Discretionary investors will gravitate towards algorithms to
                  remain competitive.
                </div>
                <div className="w-full md:w-[95%] text-[14px] leading-[28px] md:text-[16px] md:leading-[30px] text-[#ffffff]/60 font-poppins mt-8">
                  Even if they choose not to automate their systems,
                  discretionary traders should formalise their process to the
                  point of being computer coded. This sheds light on the blind
                  spots in their process.
                </div>
              </div>
              <div className="flex flex-row justify-between items-center mt-8">
                <div>
                  <div className="text-md md:text-xl font-sans text-white font-bold mb-1.5">
                    - Laurent Bernut
                  </div>
                  <div className=" text-[12px] md:text-base font-sans text-[#ffffff]/60">
                    ( CEO - ASC Capital, Ex- fidelity, HSBC )
                  </div>
                </div>
                <div>
                  <img
                    src={Quote}
                    alt="Quote"
                    className="w-[50px] md:w-[80px]"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      */}
    </div>
  );
};

export default AllPart;
