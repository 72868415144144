import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "./components/HomePage";
import Advisors from "./components/Advisor";
import Investors from "./components/Investors";
import TermsOfServices from "./components/TermsOfService";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/advisors" exact element={<Advisors />} />
        <Route path="/investors" exact element={<Investors />} />
        <Route path="/terms-of-services" exact element={<TermsOfServices />} />
        <Route path="/" element={<HomePage />} />
      </Routes>
    </div>
  );
}

export default App;
