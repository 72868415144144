import React from "react";

import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div id="Footer" className=" w-full bg-black">
      {/*------------------------Lower----------------------------- */}
      <div className="w-full max-w-screen-xl mx-auto flex flex-col-reverse md:flex-row justify-center items-center  py-4 border-t border-neutral-900">
        <div className="w-full md:w-auto flex flex-col md:flex-row justify-center  space-y-1 md:space-y-0 items-center mb-3 md:mb-0  md:space-x-8">
          <a
            href={"https://calendly.com/gopesh-iitk/30min"}
            target="_blank"
            rel="noreferrer noopener"
          >
            <div className="md:-pl-6 pr-6 md:pr-0 text-base font-Poppins cursor-pointer leading-6 text-slate-50 hover:scale-105 hover:text-[#01B0EA] ease-linear duration-150">
              Contact us for subscription
            </div>
          </a>
          <Link to="terms-of-services">
            <div className="md:pl-6 md:border-l md:border-[#ffffff]/50 text-base font-Poppins cursor-pointer leading-6 text-slate-50 hover:scale-105 hover:text-[#01B0EA] ease-linear duration-150">
              Terms of Service
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
